<template>
  <div class="content">
    <div class="page">
      <h2>网格化管理</h2>
      <div class="message">
        融合社区内部人、地、物、事、情、组织等信息于一体，以GIS可视化地图的形式将网格信息、人员、事件、部件等信息集成作统一的可视化呈现，减轻治理工作负担、提升治理工作效果。
      </div>
      <img src="../../../../assets/image/theBasicLevel/u992.png" alt="" />
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  box-sizing: border-box;
  min-height: calc(100vh - 387px);
  width: 100%;
  margin-bottom: 50px;
  .page {
    margin-top: 50px;
    width: 100%;
    height: 720px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px;
    // position: relative;
    text-align: center;
    > h2 {
      text-align-last: left;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 10px 0 30px 0;
    }
    .message {
      text-align-last: left;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
      margin-bottom: 50px;
    }
  }
}
</style>